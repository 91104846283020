import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;

const CreateProduct = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [oldprice, setOldprice] = useState("");
  const [category, setCategory] = useState("");
  const [shipping, setShipping] = useState("");
  const [colorInputs, setColorInputs] = useState([
    { name: "", quantity: 0, photos: [] },
  ]);
  const [isClicked, setIsClicked] = useState(false);

  //get all categories
  const getAllCategory = async () => {
    try {
      const productType = "women";
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/category/get-category?productType=${productType}`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleColorPhotosChange = (index, files) => {
    const updatedColors = [...colorInputs];
    updatedColors[index].photos = files;
    setColorInputs(updatedColors);
  };

  const addColorInput = () => {
    setColorInputs([...colorInputs, { name: "", quantity: 0 }]);
  };
  const handleColorChange = (index, field, value) => {
    const updatedColors = [...colorInputs];
    updatedColors[index][field] = value;
    setColorInputs(updatedColors);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("description", description);
      productData.append("price", price);
      productData.append("oldprice", oldprice);
      productData.append("category", category);
      productData.append("shipping", shipping);

      // Add colors with associated photos
      productData.append("colors", JSON.stringify(colorInputs));
      colorInputs.forEach((color, index) => {
        if (color.photos?.length) {
          color.photos.forEach((photo, i) => {
            productData.append(`color_${index}_photo_${i}`, photo);
          });
        }
      });
      const productType = "women";

      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/product/create-product?productType=${productType}`,
        productData
      );

      if (data?.success) {
        setIsClicked(false);
        toast.success("Product created successfully");
        navigate("/dashboard/admin/products");
      } else {
        toast.error(data?.message);
        setIsClicked(false);
      }
    } catch (error) {
      console.error(error);
      setIsClicked(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Dashboard - Create Women Product"}>
      <Container className="m-3 p-3">
        <Row>
          <Col md={3}>
            <AdminMenu />
          </Col>
          <Col md={9}>
            <h1>Create Women Product</h1>
            <div className="m-1 w-75">
              <Select
                bordered={false}
                placeholder="Select a category"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => {
                  setCategory(value);
                }}
              >
                {categories?.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
              {photos.length > 0 && (
                <div className="text-center">
                  {Array.from(photos).map((photo, index) => (
                    <img
                      key={index}
                      src={URL.createObjectURL(photo)}
                      alt={`product_photo_${index}`}
                      height={"200px"}
                      className="img img-responsive m-2"
                    />
                  ))}
                </div>
              )}

              <div className="mb-3">
                {photos.length > 0 && (
                  <div className="text-center">
                    {Array.from(photos).map((photo, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(photo)}
                        alt={`product_photo_${index}`}
                        height={"200px"}
                        className="img img-responsive m-2"
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="Write a name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  rows="4"
                  value={description}
                  placeholder="Write a description"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="Write a selling Price"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={oldprice}
                  placeholder="Write a List Price"
                  className="form-control"
                  onChange={(e) => setOldprice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Select
                  bordered={false}
                  placeholder="Select Shipping"
                  size="large"
                  showSearch
                  className="form-select mb-3"
                  onChange={(value) => {
                    setShipping(value);
                  }}
                >
                  <Option value="0">No</Option>
                  <Option value="1">Yes</Option>
                </Select>
              </div>

              {/* Color Selection */}
              <div className="mb-3">
                <label>Colors and Quantities:</label>
                {colorInputs.map((input, index) => (
                  <div key={index} className="mb-3 border p-3">
                    <input
                      type="text"
                      placeholder="Color"
                      value={input.name}
                      className="form-control mb-2"
                      onChange={(e) =>
                        handleColorChange(index, "name", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Quantity"
                      value={input.quantity}
                      className="form-control mb-2"
                      onChange={(e) =>
                        handleColorChange(index, "quantity", e.target.value)
                      }
                    />
                    <input
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleColorPhotosChange(
                          index,
                          Array.from(e.target.files)
                        )
                      }
                      className="form-control mb-2"
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        const updatedColors = colorInputs.filter(
                          (_, i) => i !== index
                        ); // Remove the selected color input
                        setColorInputs(updatedColors);
                      }}
                    >
                      Remove Color
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  className="btn btn-secondary mt-2"
                  onClick={addColorInput}
                >
                  Add Color
                </button>
              </div>

              <div className="mb-3">
                {!isClicked ? (
                  <button className="btn btn-primary" onClick={handleCreate}>
                    CREATE PRODUCT
                  </button>
                ) : (
                  <button className="btn btn-primary" disabled>
                    Creating...
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default CreateProduct;
