// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg_login {
    background: #fb1c15;
    background: linear-gradient(0deg, rgba(251, 28, 21, 1) 0%, rgba(255, 106, 100, 1) 35%);
    border: none;
    color: #fff;
    font-weight: bold;
    padding: 6px 20px;
    text-transform: capitalize;
}

a {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,sFAAsF;IACtF,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".bg_login {\r\n    background: #fb1c15;\r\n    background: linear-gradient(0deg, rgba(251, 28, 21, 1) 0%, rgba(255, 106, 100, 1) 35%);\r\n    border: none;\r\n    color: #fff;\r\n    font-weight: bold;\r\n    padding: 6px 20px;\r\n    text-transform: capitalize;\r\n}\r\n\r\na {\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
