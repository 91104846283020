import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import AdminMenu from "../../AdminMenu/AdminMenu";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "antd";
const { Option } = Select;

const UpdateProduct = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [oldprice, setOldprice] = useState("");
  const [category, setCategory] = useState("");
  const [shipping, setShipping] = useState("");
  const [id, setId] = useState("");
  const [colorInputs, setColorInputs] = useState([
    { name: "", quantity: 0, photos: [] },
  ]);

  // Get single product
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/product/get-product/${params.slug}`
      );
      setName(data.product.name);
      setId(data.product._id);
      setDescription(data.product.description);
      setPrice(data.product.price);
      setOldprice(data.product.oldprice);
      setCategory(data.product.category._id);
      setShipping(data.product.shipping);
      setColorInputs(
        data.product.colors || [{ name: "", quantity: 0, photos: [] }]
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
    //eslint-disable-next-line
  }, []);

  // Get all categories
  const getAllCategory = async () => {
    try {
      const productType = "women";
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/category/get-category?productType=${productType}`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong in getting category");
    }
  };

  useEffect(() => {
    getAllCategory();
  }, []);

  const handleColorChange = (index, field, value) => {
    const updatedColors = [...colorInputs];
    updatedColors[index][field] = value;
    setColorInputs(updatedColors);
  };

  const handleColorPhotosChange = (index, files) => {
    const updatedColors = [...colorInputs];
    updatedColors[index].photos = files;
    setColorInputs(updatedColors);
  };

  const addColorInput = () => {
    setColorInputs([...colorInputs, { name: "", quantity: 0, photos: [] }]);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const productData = new FormData();
      productData.append("name", name);
      productData.append("description", description);
      productData.append("price", price);
      productData.append("oldprice", oldprice);
      productData.append("category", category);
      productData.append("shipping", shipping);

      // Add colors with associated photos
      productData.append("colors", JSON.stringify(colorInputs));
      colorInputs.forEach((color, index) => {
        if (color.photos?.length) {
          color.photos.forEach((photo, i) => {
            productData.append(`color_${index}_photo_${i}`, photo);
          });
        }
      });

      const { data } = await axios.put(
        `${process.env.REACT_APP_API}/api/v1/product/update-product/${id}`,
        productData
      );
      if (data?.success) {
        toast.success("Product updated successfully");
        navigate("/dashboard/admin/products");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  const handleDelete = async () => {
    try {
      let answer = window.prompt(
        "Are you sure you want to delete this product?"
      );
      if (!answer) return;
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API}/api/v1/product/delete-product/${id}`
      );
      toast.success("Product deleted successfully");
      navigate("/dashboard/admin/products");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Dashboard - Update Product"}>
      <Container className="m-3 p-3">
        <Row>
          <Col md={3}>
            <AdminMenu />
          </Col>
          <Col md={9}>
            <h1>Update Women Product</h1>
            <div className="m-1 w-75">
              <Select
                bordered={false}
                placeholder="Select a category"
                size="large"
                showSearch
                className="form-select mb-3"
                onChange={(value) => setCategory(value)}
                value={category}
              >
                {categories?.map((c) => (
                  <Option key={c._id} value={c._id}>
                    {c.name}
                  </Option>
                ))}
              </Select>
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="Write a name"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  rows="4"
                  value={description}
                  placeholder="Write a description"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="Write a selling Price"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={oldprice}
                  placeholder="Write a List Price"
                  className="form-control"
                  onChange={(e) => setOldprice(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <label>Colors and Quantities:</label>
                {colorInputs.map((input, index) => (
                  <div key={index} className="mb-3 border p-3">
                    <input
                      type="text"
                      placeholder="Color"
                      value={input.name}
                      className="form-control mb-2"
                      onChange={(e) =>
                        handleColorChange(index, "name", e.target.value)
                      }
                    />
                    <input
                      type="number"
                      placeholder="Quantity"
                      value={input.quantity}
                      className="form-control mb-2"
                      onChange={(e) =>
                        handleColorChange(index, "quantity", e.target.value)
                      }
                    />
                    <input
                      type="file"
                      multiple
                      onChange={(e) =>
                        handleColorPhotosChange(
                          index,
                          Array.from(e.target.files)
                        )
                      }
                      className="form-control mb-2"
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => {
                        const updatedColors = colorInputs.filter(
                          (_, i) => i !== index
                        );
                        setColorInputs(updatedColors);
                      }}
                    >
                      Remove Color
                    </button>
                  </div>
                ))}

                <button
                  type="button"
                  className="btn btn-secondary mt-2"
                  onClick={addColorInput}
                >
                  Add Color
                </button>
              </div>

              <div className="mb-3">
                <Select
                  bordered={false}
                  placeholder="Select Shipping"
                  size="large"
                  showSearch
                  className="form-select mb-3"
                  onChange={(value) => setShipping(value)}
                  value={shipping ? "1" : "0"}
                >
                  <Option value="0">No</Option>
                  <Option value="1">Yes</Option>
                </Select>
              </div>
              <div className="mb-3">
                <button className="btn btn-primary" onClick={handleUpdate}>
                  UPDATE PRODUCT
                </button>
              </div>
              <div className="mb-3">
                <button className="btn btn-danger" onClick={handleDelete}>
                  DELETE PRODUCT
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default UpdateProduct;
