// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgContainer {
    /* background: rgb(255,227,227); */
    background: linear-gradient(29deg, #f5d4d473 0%, #ffffff48 35%);
}`, "",{"version":3,"sources":["webpack://./src/components/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,+DAA+D;AACnE","sourcesContent":[".imgContainer {\r\n    /* background: rgb(255,227,227); */\r\n    background: linear-gradient(29deg, #f5d4d473 0%, #ffffff48 35%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
