import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { Checkbox, Radio } from "antd";
import { Prices } from "./Prices";
import { useNavigate } from "react-router-dom";

const KidFilterProducts = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Get all categories
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-category/kid-get-category`
      );
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);

  // Get all products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-list/${page}`
      );
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // Get total count
  const getTotal = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-count`
      );
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);

  // Load more products
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-list/${page}`
      );
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // Handle category filter
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };

  useEffect(() => {
    if (!checked.length || !radio.length) getAllProducts();
  }, [checked.length, radio.length]);

  useEffect(() => {
    if (checked.length || radio.length) filterProduct();
  }, [checked, radio]);

  // Filter products
  const filterProduct = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-filters`,
        { checked, radio }
      );
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <Container fluid className="m-3 p-3">
        <div className="row">
          <div className="col-md-2">
            <h4 className="text-center">Filter By Category</h4>
            <div className="d-flex flex-column">
              {categories?.map((c) => (
                <Checkbox
                  key={c._id}
                  onChange={(e) => handleFilter(e.target.checked, c._id)}
                >
                  {c.name}
                </Checkbox>
              ))}
            </div>
            {/* Price filter */}
            <h4 className="text-center mt-4">Filter By Price</h4>
            <div className="d-flex flex-column">
              <Radio.Group onChange={(e) => setRadio(e.target.value)}>
                {Prices?.map((p) => (
                  <div key={p._id}>
                    <Radio value={p.array}>{p.name}</Radio>
                  </div>
                ))}
              </Radio.Group>
            </div>
            <div className="d-flex flex-column">
              <button
                className="btn btn-danger"
                onClick={() => window.location.reload()}
              >
                RESET FILTERS
              </button>
            </div>
          </div>
          <div className="col-md-10">
            <h1 className="text-center">All Products</h1>
            <div className="row">
              <div className="d-flex flex-wrap">
                {products?.map((p) => {
                  const discountPercentage = p.oldprice
                    ? Math.round(((p.oldprice - p.price) / p.oldprice) * 100)
                    : null;

                  return (
                    <Col
                      className="col-6 col-sm-4 col-md-3 p-1 mt-3"
                      key={p._id}
                    >
                      <Card>
                        {discountPercentage && (
                          <div>
                            <span
                              style={{
                                margin: "-15px",
                                position: "static",
                                marginRight: "5px",
                                height: "18px",
                                lineHeight: "18px",
                                borderRadius: "3px",
                                minWidth: "40px",
                                padding: "0 5px",
                                textAlign: "center",
                                fontWeight: "500",
                                fontSize: "12px",
                                float: "left",
                                backgroundColor: "#dc3545",
                                color: "white",
                              }}
                            >
                              {discountPercentage}% OFF
                            </span>
                          </div>
                        )}
                        <Card.Img
                          src={`${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-photo/${p._id}`}
                          alt={p.name}
                          style={{ height: "18rem" }}
                        />
                        <Card.Body>
                          <Card.Title className="text-dark">
                            {p.name}
                          </Card.Title>
                          <Card.Text>
                            <p className="text-secondary ">
                              {p.description.substring(0, 25)}...
                            </p>
                            <Row>
                              <Col>
                                ₹{p.price}
                                {p.oldprice && (
                                  <div
                                    className="ms-2"
                                    style={{
                                      textDecoration: "line-through",
                                      color: "#999",
                                      fontSize: "14px",
                                      display: "inline-block",
                                    }}
                                  >
                                    ₹{p.oldprice}
                                  </div>
                                )}
                              </Col>
                              <Col className="my-2">
                                <Button
                                  onClick={() =>
                                    navigate(`/kid-product/${p.slug}`)
                                  }
                                  variant="outline-danger"
                                  size="sm"
                                >
                                  More Details
                                </Button>
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                })}
              </div>
            </div>
            <div className="m-2 p-3">
              {products && products.length < total && (
                <button
                  className="btn btn-warning"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(page + 1);
                  }}
                >
                  {loading ? "Loading ..." : "Loadmore"}
                </button>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default KidFilterProducts;
