import React from "react";
import Layout from "./../Layout";
import { useSearch } from "../../context/search";
import { Card, Col, Row, Button } from "react-bootstrap";

const Search = () => {
  const [values, setValues] = useSearch();
  return (
    <Layout title={"Search results"}>
      <div className="container">
        <div className="text-center">
          <h1>Search Results</h1>
          <h6>
            {values?.results.length < 1
              ? "No Products Found"
              : `Found ${values?.results.length}`}
          </h6>
          <Row className="mt-4  d-flex">
            {values?.results.map((p) => (
              <Col className="col-6 col-sm-4 col-md-3 mt-3">
                <Card>
                  <div>
                    <span
                      style={{
                        margin: "-15px",
                        position: "static",
                        marginRight: "5px",
                        height: "18px",
                        lineHeight: "18px",
                        borderRadius: "3px",
                        minWidth: "40px",
                        padding: "0 5px",
                        textAlign: "center",
                        fontWeight: "500",
                        fontSize: "12px",
                        float: "left",
                        backgroundColor: "#dc3545",
                        color: "white",
                      }}
                    >
                      50%
                    </span>
                  </div>
                  <Card.Img
                    src={`${process.env.REACT_APP_API}/api/v1/product/product-photo/${p._id}`}
                    alt={p.name}
                    style={{ height: "18rem" }}
                  />
                  <Card.ImgOverlay>
                    <Card.Title className="text-dark">{p.name}</Card.Title>
                  </Card.ImgOverlay>
                  <Card.Body>
                    <Card.Text>
                      <p className="text-secondary ">
                        {p.description.substring(0, 25)}...
                      </p>
                      <Row>
                        <Col>
                          {p.price}
                          <div
                            style={{
                              textDecoration: "line-through",
                              color: "#999",
                              fontSize: "14px",
                              display: "inline-blockS",
                            }}
                          >
                            {p.oldprice}
                          </div>
                        </Col>
                        <Col className="my-2">
                          <Button variant="outline-danger" size="sm">
                            Buy Now
                          </Button>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Search;
