// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h_main {
    height: 100%;
    width: 100%;
    background: rgb(255, 227, 227);
    background: linear-gradient(29deg, #ffe3e3 0%, #ffffff 35%);
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderMain/HeaderMain.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,8BAA8B;IAC9B,2DAA2D;AAC/D","sourcesContent":[".h_main {\r\n    height: 100%;\r\n    width: 100%;\r\n    background: rgb(255, 227, 227);\r\n    background: linear-gradient(29deg, #ffe3e3 0%, #ffffff 35%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
