// import { useState, useContext, createContext, useEffect } from "react";

// const CartContext = createContext();

// const CartProvider = ({ children }) => {
//   const [cart, setCart] = useState([]);

//   useEffect(() => {
//     let existingCartItem = localStorage.getItem("cart");
//     if (existingCartItem) setCart(JSON.parse(existingCartItem));
//   }, []);

//   return (
//     <CartContext.Provider value={[cart, setCart]}>
//       {children}
//     </CartContext.Provider>
//   );
// };

// //custom hook
// const useCart = () => useContext(CartContext);

// export { useCart, CartProvider };

import { useState, useContext, createContext, useEffect } from "react";
import LZString from "lz-string"; // Install using npm install lz-string

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  // Load cart from localStorage on initial render
  useEffect(() => {
    try {
      const compressedCart = localStorage.getItem("cart");
      if (compressedCart) {
        const savedCart = LZString.decompressFromUTF16(compressedCart) || [];
        setCart(JSON.parse(savedCart));
      }
    } catch (error) {
      console.error("Failed to load cart from localStorage:", error);
    }
  }, []);

  // Save cart to localStorage whenever it changes
  useEffect(() => {
    try {
      // Only store essential fields in the cart
      const minimalCart = cart.map(
        ({ id, name, price, quantity, selectedColor }) => ({
          id,
          name,
          price,
          quantity,
          selectedColor,
        })
      );

      // Check if data size exceeds a threshold (e.g., 5MB)
      const dataSize = new Blob([JSON.stringify(minimalCart)]).size;
      if (dataSize > 5 * 1024 * 1024) {
        console.warn(
          "Cart data exceeds storage limit. Consider alternative storage solutions."
        );
        return;
      }

      // Compress data before saving
      const compressedCart = LZString.compressToUTF16(
        JSON.stringify(minimalCart)
      );
      localStorage.setItem("cart", compressedCart);
    } catch (error) {
      console.error("Failed to save cart to localStorage:", error);
    }
  }, [cart]);

  return (
    <CartContext.Provider value={[cart, setCart]}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
