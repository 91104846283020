// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description-container {
    position: relative;
    max-width: 600px;
    margin: 20px auto;
    overflow: hidden;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.read-more {
    display: none;
    color: blue;
    cursor: pointer;
    margin-top: 10px;
}

.description-container.expanded .description {
    display: block;
    -webkit-line-clamp: unset;
}

.description-container.expanded .read-more {
    display: none;
}

.description-container .read-more {
    display: inline;
}
`, "",{"version":3,"sources":["webpack://./src/components/Products/Products.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".description-container {\r\n    position: relative;\r\n    max-width: 600px;\r\n    margin: 20px auto;\r\n    overflow: hidden;\r\n}\r\n\r\n.description {\r\n    display: -webkit-box;\r\n    -webkit-line-clamp: 2;\r\n    -webkit-box-orient: vertical;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n}\r\n\r\n.read-more {\r\n    display: none;\r\n    color: blue;\r\n    cursor: pointer;\r\n    margin-top: 10px;\r\n}\r\n\r\n.description-container.expanded .description {\r\n    display: block;\r\n    -webkit-line-clamp: unset;\r\n}\r\n\r\n.description-container.expanded .read-more {\r\n    display: none;\r\n}\r\n\r\n.description-container .read-more {\r\n    display: inline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
