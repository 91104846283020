export const Prices = [
  {
    _id: 0,
    name: "0₹ to 99₹",
    array: [0, 99],
  },
  {
    _id: 1,
    name: "100₹ to 199₹",
    array: [100, 199],
  },
  {
    _id: 2,
    name: "200₹ to 299₹",
    array: [200, 299],
  },
  {
    _id: 3,
    name: "300₹ to 399₹",
    array: [300, 399],
  },
  {
    _id: 4,
    name: "400₹ to more",
    array: [400, 100000000],
  },
];
