import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useCart } from "../../context/cart";
import toast from "react-hot-toast";

const KidProductDetails = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  const [relatedProduct, setRelatedProduct] = useState([]);
  const navigate = useNavigate();
  const [cart, setCart] = useCart();

  //initial p details
  useEffect(() => {
    if (params?.slug) getProduct();
  }, [params?.slug]);

  //get product
  const getProduct = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-get-product/${params.slug}`
      );
      setProduct(data?.product);
      getSimilarProduct(data?.product._id, data?.product.category._id);
    } catch (error) {
      console.log(error);
    }
  };

  //get similar product
  const getSimilarProduct = async (pid, cid) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/kid-product/kid-related-product/${pid}/${cid}`
      );
      setRelatedProduct(data?.products);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="container">
        <div className="row mt-2">
          <div className="col-md-4">
            <img
              src={`${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-photo/${product._id}`}
              alt={product.name}
              className="card-img-top"
              style={{ height: "18rem" }}
            />
          </div>
          <div className="col-md-8">
            <h1 className="text-center">Product Details</h1>
            <h6>Name : {product.name}</h6>
            <h6>Description : {product.description}</h6>
            <h6>Price : {product.price}</h6>
            <h6>Category : {product.category?.name}</h6>
            <button
              className="btn btn-secondary ms-1"
              onClick={() => {
                setCart([...cart, product]);
                localStorage.setItem(
                  "cart",
                  JSON.stringify([...cart, product])
                );
                toast.success("Item Added to cart");
              }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
      <hr />
      <div className="container">
        <Row className="my-3  d-flex">
          <h1 className="mt-3">Similar Products</h1>
          {relatedProduct.length < 1 && (
            <p className="text-center">No Similar products found</p>
          )}
          {relatedProduct?.map((p) => (
            <Col className=" col-sm-6 col-md-3 mt-2 ">
              <Card>
                <div>
                  <span
                    style={{
                      margin: "-15px",
                      position: "static",
                      marginRight: "5px",
                      height: "18px",
                      lineHeight: "18px",
                      borderRadius: "3px",
                      minWidth: "40px",
                      padding: "0 5px",
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "12px",
                      float: "left",
                      backgroundColor: "#dc3545",
                      color: "white",
                    }}
                  >
                    50%
                  </span>
                </div>
                <Card.Img
                  src={`${process.env.REACT_APP_API}/api/v1/kid-product/kid-product-photo/${p._id}`}
                  alt={p.name}
                  style={{ height: "9rem" }}
                />
                <Card.Body>
                  <Card.Title className="text-dark">{p.name}</Card.Title>
                  <Card.Text>
                    <p className="text-secondary ">
                      {p.description.substring(0, 25)}...
                    </p>
                    <Row>
                      <Col>
                        {p.price}
                        <div
                          style={{
                            textDecoration: "line-through",
                            color: "#999",
                            fontSize: "14px",
                            display: "inline-blockS",
                          }}
                        >
                          {p.oldprice}
                        </div>
                      </Col>
                      <Col className="my-2">
                        <Button
                          onClick={() => navigate(`/kid-product/${p.slug}`)}
                          variant="outline-danger"
                          size="sm"
                        >
                          More Details
                        </Button>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  );
};

export default KidProductDetails;
