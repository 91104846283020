// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head_left .h_subTitle{
    background: #FFEEDE;
    color: #FB9431;
    font-weight: bold;
    padding: 7px 20px;
    border-radius: 20px;
}
.h_title h1{
    font-size: 50px;
}
.contact_btn{
    border:1px solid #fb1c15 !important;
    background-color: transparent !important;
    color: #fb1c15 !important; 
    font-weight: bold !important;
    text-transform: capitalize;
}
.head_right .imageContainer{
    width: 100%;
    height: 100%;
}
.imageContainer .head_rightImg{
    width: 90%;
    height: 90%;
    object-fit: cover;
}
.curve{
    width: 8rem;
    height: 100%;
    object-fit: cover;
}
.starr{
    color: #FB9431;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;IACI,mCAAmC;IACnC,wCAAwC;IACxC,yBAAyB;IACzB,4BAA4B;IAC5B,0BAA0B;AAC9B;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,UAAU;IACV,WAAW;IACX,iBAAiB;AACrB;AACA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB","sourcesContent":[".head_left .h_subTitle{\r\n    background: #FFEEDE;\r\n    color: #FB9431;\r\n    font-weight: bold;\r\n    padding: 7px 20px;\r\n    border-radius: 20px;\r\n}\r\n.h_title h1{\r\n    font-size: 50px;\r\n}\r\n.contact_btn{\r\n    border:1px solid #fb1c15 !important;\r\n    background-color: transparent !important;\r\n    color: #fb1c15 !important; \r\n    font-weight: bold !important;\r\n    text-transform: capitalize;\r\n}\r\n.head_right .imageContainer{\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n.imageContainer .head_rightImg{\r\n    width: 90%;\r\n    height: 90%;\r\n    object-fit: cover;\r\n}\r\n.curve{\r\n    width: 8rem;\r\n    height: 100%;\r\n    object-fit: cover;\r\n}\r\n.starr{\r\n    color: #FB9431;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
