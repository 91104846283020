import React, { useState } from "react";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const SearchInput = () => {
  const [values, setValue] = useSearch();
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show the spinner when search is in progress
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API}/api/v1/product/search/${values.keyword}`
      );
      setValue({ ...values, results: data });
      navigate("/search");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Hide the spinner once the API call is finished
    }
  };

  return (
    <div>
      <form className="d-flex" role="search" onSubmit={handleSubmit}>
        <input
          className="form-control me-2"
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={values.keyword}
          onChange={(e) => setValue({ ...values, keyword: e.target.value })}
        />
        {loading ? (
          <button disabled className="btn btn-danger" type="submit">
            Searching...
          </button>
        ) : (
          <button className="btn btn-outline-danger" type="submit">
            Search
          </button>
        )}
      </form>
    </div>
  );
};

export default SearchInput;
